import React, { useState, useEffect } from 'react';
import DOMPurify from 'dompurify';  // Ensure DOMPurify is imported for sanitizing HTML
import he from 'he';  // Import the 'he' library to decode HTML entities
import loadConfig from '../config/config';  // Import config loader
import { getTimeSincePublication } from '../utils/timeHelpers';  // Import the time helper
import './NewsFeed.css';  // Import styles for the news feed and loading
import { parseISO, parse } from 'date-fns';  // Import date parsing functions from date-fns
import { ar } from 'date-fns/locale';  // Import Arabic locale

// Function to get a random default thumbnail from the config
function getRandomThumbnail(config) {
    if (!config || !config.defaultThumbnailPaths || !config.defaultThumbnailPaths.length) {
        console.error('NewsFeed: defaultThumbnailPaths is undefined or empty');  // Log an error if config is not properly loaded
        return '/images/default-thumbnail01.webp';  // Return a fallback thumbnail
    }

    const randomIndex = Math.floor(Math.random() * config.defaultThumbnailPaths.length);
    const selectedThumbnail = config.defaultThumbnailPaths[randomIndex];
    return selectedThumbnail;
}

// Function to clean text (remove HTML tags, hyperlinks, and decode HTML entities)
function cleanText(htmlContent) {
    let sanitizedContent = DOMPurify.sanitize(htmlContent);
    sanitizedContent = sanitizedContent.replace(/(<([^>]+)>)/gi, ""); // Strip HTML tags
    sanitizedContent = sanitizedContent.replace(/(https?:\/\/[^\s]+)/g, ""); // Remove URLs
    sanitizedContent = sanitizedContent.trim(); // Remove leading/trailing whitespace

    // Decode HTML entities such as &#039; (apostrophe) or &quot; (quotes)
    const decodedContent = he.decode(sanitizedContent);
    return decodedContent;
}

// Function to attempt multiple date parsing strategies
function tryParseDate(dateString) {
    let parsedDate;

    // Try to parse ISO string first
    parsedDate = parseISO(dateString);
    if (!isNaN(parsedDate)) {
        return parsedDate;
    }

    // Try to parse "day month" format with Arabic locale and add the current year
    try {
        const currentYear = new Date().getFullYear();
        parsedDate = parse(`${dateString} ${currentYear}`, 'd MMMM yyyy', new Date(), { locale: ar });
        if (!isNaN(parsedDate)) {
            return parsedDate;
        }
    } catch (error) {
        console.error(`NewsFeed: Failed to parse Day-Month Date: ${error}`);
    }

    // Fallback to parsing with `new Date()`
    parsedDate = new Date(dateString);
    if (!isNaN(parsedDate)) {
        return parsedDate;
    }

    console.warn(`NewsFeed: Failed to parse date: ${dateString}`);
    return null;
}

// Function to trim the description to approximately two lines
function trimDescription(text, maxLength = 150) {
    if (text.length > maxLength) {
        const trimmedText = text.slice(0, maxLength) + '...';
        return trimmedText;
    }
    return text;
}

function NewsFeed({ articles, onSelectArticle, selectedArticle }) {
    const [processedArticles, setProcessedArticles] = useState([]);

    // Load config and initialize articles with thumbnails
    useEffect(() => {
        const initializeArticles = async () => {
            try {
                const loadedConfig = await loadConfig();
                const initializedArticles = articles.map(article => {
                    const thumbnail = article.imageUrl ? article.imageUrl : getRandomThumbnail(loadedConfig);
                    return { ...article, thumbnail };
                });
                setProcessedArticles(initializedArticles);
            } catch (error) {
                console.error('NewsFeed: Error loading config or initializing articles:', error);
            }
        };

        initializeArticles();
    }, [articles]);

    // Sort articles by publication date in descending order
    const sortedArticles = [...processedArticles].sort((a, b) => {
        const dateA = tryParseDate(a.pubDate);
        const dateB = tryParseDate(b.pubDate);

        // Fallback to using Date.parse if parsing fails
        const timeA = dateA ? dateA.getTime() : Date.parse(a.pubDate);
        const timeB = dateB ? dateB.getTime() : Date.parse(b.pubDate);

        return timeB - timeA;
    });

    return (
        <div className="news-feed" data-testid="news-feed">
            {sortedArticles.map((article, index) => {
                const formattedDate = getTimeSincePublication(article.pubDate);
                const cleanedDescription = cleanText(article.description || article.title);
                const trimmedDescription = trimDescription(cleanedDescription);
                const cleanedTitle = cleanText(article.title);


                return (
                    <div
                        key={index}
                        className={`article-card ${selectedArticle === article ? 'selected' : ''}`}
                        onClick={() => {
                            onSelectArticle({ article, thumbnail: article.thumbnail });
                        }}
                        data-testid={`article-card-${index}`}
                    >
                        <div className="article-thumbnail-container" data-testid={`article-thumbnail-container-${index}`}>
                            <img
                                src={article.thumbnail}
                                alt={article.title || "Article thumbnail"}
                                className="article-thumbnail"
                                data-testid={`article-thumbnail-${index}`}
                                onError={(e) => {
                                    // Fallback to a random thumbnail on error
                                    const loadedConfig = loadConfig();  // Load config to access random thumbnails
                                    e.target.src = getRandomThumbnail(loadedConfig);
                                }}
                            />
                            <div className="article-info" data-testid={`article-info-${index}`}>
                                <small className="article-date" data-testid={`article-date-${index}`}>{formattedDate}</small>
                                <small className="article-source" data-testid={`article-source-${index}`}>{article.source}</small>
                            </div>
                        </div>

                        <div className="article-content" data-testid={`article-content-${index}`}>
                            <h3 className="article-title" data-testid={`article-title-${index}`}>{cleanedTitle}</h3>
                            <p className="article-description" data-testid={`article-description-${index}`}>{trimmedDescription}</p>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default NewsFeed;
