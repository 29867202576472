import React, { useState, useEffect, Suspense } from 'react';
import DOMPurify from 'dompurify';
import he from 'he';  // Import 'he' library to decode HTML entities
import './Accordion.css';
import { getTimeSincePublication } from '../utils/timeHelpers';
import { generateThumbnails } from '../utils/newsHelpers';  // Import the shared utility function
import { parseISO } from 'date-fns';  // Keep parseISO since it's used for date parsing
import loadConfig from '../config/config';  // Ensure config is loaded

// Function to clean text (remove HTML tags and decode HTML entities)
function cleanText(htmlContent) {
  let sanitizedContent = DOMPurify.sanitize(htmlContent);  // Sanitize HTML
  sanitizedContent = sanitizedContent.replace(/(<([^>]+)>)/gi, "");  // Strip HTML tags
  sanitizedContent = sanitizedContent.trim();  // Remove leading/trailing whitespace
  return he.decode(sanitizedContent);  // Decode HTML entities
}

function NewsAccordion({ articles }) {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [thumbnails, setThumbnails] = useState([]);
  const [sanitizedArticles, setSanitizedArticles] = useState([]);
  const [loading, setLoading] = useState(true); // State to track loading

  // Load config and then thumbnails
  useEffect(() => {
    const loadConfigAndThumbnails = async () => {
      try {
        setLoading(true);
        const loadedConfig = await loadConfig(); // Load config

        // Sort articles by date (newest first)
        const sortedArticles = [...articles].sort((a, b) => {
          const dateA = parseISO(a.pubDate);
          const dateB = parseISO(b.pubDate);
          return (dateB || new Date(b.pubDate)) - (dateA || new Date(a.pubDate));
        });

        const loadedThumbnails = generateThumbnails(sortedArticles, loadedConfig); // Pass config to generateThumbnails
        const sanitizedContent = sortedArticles.map(article => {
          const description = article.description || article.title;
          const sanitized = DOMPurify.sanitize(description, {
            ALLOWED_TAGS: ['p', 'b', 'i', 'em', 'strong'],
          });
          return { ...article, sanitizedContent: sanitized };
        });

        setThumbnails(loadedThumbnails);
        setSanitizedArticles(sanitizedContent);
        setLoading(false);
      } catch (error) {
        console.error('Error loading articles or config:', error);
        setLoading(false);
      }
    };

    loadConfigAndThumbnails();
  }, [articles]);

  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div className="accordion" data-testid="news-accordion">
        {loading ? (
          <div className="loading-spinner" data-testid="loading-spinner">
            <img src="/images/loading-icon.png" alt="Loading..." className="loading-logo" />
          </div>
        ) : (
          sanitizedArticles.map((article, index) => {
            const cleanedTitle = cleanText(article.title);
            const formattedDate = getTimeSincePublication(article.pubDate);
            const imageUrl = article.imageUrl || thumbnails[index];

            return (
              <div key={index} className="accordion-item" data-testid={`accordion-item-${index}`}>
                <div 
                  className="accordion-header" 
                  onClick={() => handleToggle(index)}
                  data-testid={`accordion-header-${index}`}
                >
                  <div className="accordion-title" data-testid={`accordion-title-${index}`}>
                    <h3>{cleanedTitle}</h3>
                  </div>

                  <div className="accordion-thumbnail-container" data-testid={`accordion-thumbnail-container-${index}`}>
                    <img 
                      src={imageUrl} 
                      alt={article.title} 
                      className="accordion-thumbnail" 
                      data-testid={`accordion-thumbnail-${index}`} 
                                                      onError={(e) => { e.target.src = '/images/default-thumbnail08.webp'; }}  // Fallback to default image on error
                    />
                    <div className="accordion-info" data-testid={`accordion-info-${index}`}>
                      <small className="article-date" data-testid={`article-date-${index}`}>{formattedDate}</small>
                      <small className="article-source" data-testid={`article-source-${index}`}>{article.source}</small>
                    </div>
                  </div>
                </div>

                <div 
                  className={`accordion-content ${expandedIndex === index ? 'expanded' : 'collapsed'}`}
                  data-testid={`accordion-content-${index}`}
                >
                  <img 
                    src={imageUrl} 
                    alt={article.title} 
                    className="accordion-body-image" 
                    data-testid={`accordion-body-image-${index}`} 
                  />
                  <div 
                    dangerouslySetInnerHTML={{ __html: article.sanitizedContent }} 
                    data-testid={`accordion-body-content-${index}`}
                  />
                  <a 
                    href={article.link} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    data-testid={`accordion-readmore-${index}`}
                  >
                    ...قراءة المزيد
                  </a>
                </div>
              </div>
            );
          })
        )}
      </div>
    </Suspense>
  );
}

export default NewsAccordion;
