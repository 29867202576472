import React from 'react';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import he from 'he';
import config from '../config/config'; // Import config.js for environment-based configuration

function cleanText(htmlContent) {
    if (!htmlContent || typeof htmlContent !== 'string') {
        return '';  // Return an empty string if the input is invalid
    }

    // Attempt to sanitize the content using DOMPurify
    let sanitizedContent;
    try {
        sanitizedContent = DOMPurify.sanitize(htmlContent);
    } catch (error) {
        console.error('DOMPurify threw an error:', error);
        sanitizedContent = '';  // Fallback to an empty string if an error occurs
    }

    // Check if DOMPurify returned something unexpected
    if (typeof sanitizedContent !== 'string') {
        console.error('DOMPurify did not return a string:', sanitizedContent);
        sanitizedContent = '';  // Fallback to an empty string
    }

    // Strip HTML tags, remove URLs, and trim whitespace
    sanitizedContent = sanitizedContent.replace(/(<([^>]+)>)/gi, "");
    sanitizedContent = sanitizedContent.replace(/(https?:\/\/[^\s]+)/g, "");
    sanitizedContent = sanitizedContent.trim();

    // Decode HTML entities
    const decodedContent = he.decode(sanitizedContent);


    return decodedContent;
}

function getRandomThumbnail() {
    if (!config || !config.defaultThumbnailPaths || !config.defaultThumbnailPaths.length) {
        console.error('defaultThumbnailPaths is undefined or empty');  // Log an error if config is not properly loaded
        return '/images/default-thumbnail01.webp';  // Return a fallback thumbnail
    }
    const randomIndex = Math.floor(Math.random() * config.defaultThumbnailPaths.length); // Use config.defaultThumbnailPaths
    return config.defaultThumbnailPaths[randomIndex]; // Use config.defaultThumbnailPaths
}

function ArticleDetails({ article = {}, thumbnail }) {
    if (!article || Object.keys(article).length === 0) {
        return <div className="article-details">يرجى اختيار مقال لعرض التفاصيل.</div>;
    }

    const cleanedTitle = cleanText(article.title || '');
    const cleanedDescription = cleanText(article.description || article.title || '');

    const pubDate = article.pubDate || 'تاريخ غير متوفر';
    const source = article.source || 'المصدر غير متوفر';
    const articleLink = article.link || '#';

    return (
        <div className="article-details" data-testid="article-details">
            <h1 data-testid="article-title">{cleanedTitle}</h1>
            <small data-testid="article-meta">{pubDate} - {source}</small>

            <img
                src={thumbnail || getRandomThumbnail()} // Use the updated getRandomThumbnail function
                alt={cleanedTitle}
                style={{ maxWidth: '100%', marginBottom: '10px' }}
                data-testid="article-thumbnail"
                onError={(e) => { e.target.src = getRandomThumbnail(); }}  // Fallback to random thumbnail on error
            />

            <div className="article-content" data-testid="article-content">
                {parse(cleanedDescription)}
            </div>

            {article.link && (
                <a href={articleLink} target="_blank" rel="noopener noreferrer" data-testid="article-link">
                    قراءة المزيد...
                </a>
            )}
        </div>
    );
}

export default ArticleDetails;
