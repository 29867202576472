import { differenceInHours } from 'date-fns';

// Function to filter news from the last 24 hours
export function filterLatestNews(articles) {
  const now = new Date();
  return articles.filter(article => {
    const articleDate = new Date(article.published);
    return differenceInHours(now, articleDate) <= 24;
  });
}

// Function to get a random thumbnail from config
function getRandomThumbnail(config) {
  if (!config || !config.defaultThumbnailPaths || !config.defaultThumbnailPaths.length) {
    console.error('defaultThumbnailPaths is undefined or empty');
    return '/images/default-thumbnail01.webp';  // Return a fallback thumbnail
  }
  const randomIndex = Math.floor(Math.random() * config.defaultThumbnailPaths.length);
  return config.defaultThumbnailPaths[randomIndex];
}

// Function to generate thumbnails for articles
export function generateThumbnails(articles, config) {
  return articles.map(article => {
    return article.imageUrl || getRandomThumbnail(config);  // Use article image or fallback to random thumbnail
  });
}