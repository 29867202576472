import React, { useState, useEffect } from 'react';
import Navbar from './components/Navbar';
import NewsFeed from './components/NewsFeed';
import ArticleDetails from './components/ArticleDetails';
import NewsAccordion from './components/NewsAccordion';
import CryptoPriceSidebar from './components/CryptoPriceSidebar'; // Import the new component
import TickerTapeWidget from './components/TickerTapeWidget'; // Import the Ticker Tape Widget
import './App.css';
import loadConfig from './config/config'; // Make sure to import loadConfig from config.js

function App() {
  const [articles, setArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [selectedArticleData, setSelectedArticleData] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [loadingAccordion, setLoadingAccordion] = useState(isMobile);
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);

  // Simulate progress bar increasing, linked to both general loading and mobile accordion loading
  useEffect(() => {
    if (loading || loadingAccordion) {
      const interval = setInterval(() => {
        setProgress((prev) => (prev < 100 ? prev + 20 : 100));
      }, 500);

      return () => clearInterval(interval);
    }
  }, [loading, loadingAccordion]);

  // Load config and fetch articles
  useEffect(() => {
    const fetchConfigAndArticles = async () => {
      try {
        // Load the config
        const config = await loadConfig();
        const apiKey = config.API_KEY;

        // Fetch articles from the NEWS API
        const response = await fetch(config.API_URLS.NEWS, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-API-Key': apiKey, // Include API key in request headers
          },
        });

  
        if (!response.ok) {
          throw new Error(`Error fetching articles: ${response.statusText}`);
        }
  
        const data = await response.json();
        setArticles(data);
        setFilteredArticles(data);
        setLoading(false);
        if (isMobile) {
          setLoadingAccordion(false);
        }
      } catch (error) {
        console.error('Error fetching articles:', error);
        setLoading(false);
        setLoadingAccordion(false);
      }
    };
  
    fetchConfigAndArticles();
  }, [isMobile]);
  

  // Function to filter articles from the last 24 hours
  const showLatestNews = () => {
    const now = new Date();

    const last24Hours = articles.filter(article => {
      const articleDate = new Date(Date.parse(article.pubDate));  // Use Date.parse for reliability
      const hoursDifference = (now - articleDate) / (1000 * 60 * 60);
      return hoursDifference < 24;
    });
    
    setFilteredArticles(last24Hours);
  };

  // Function to show all news
  const showAllNews = () => {
    setFilteredArticles(articles);
  };

  // Handle window resize to detect mobile view
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="app-grid" data-testid="app-grid">
      {/* Show the loading spinner above everything only on mobile during accordion loading */}
      {loadingAccordion && isMobile && (
        <div className="loading-spinner" data-testid="loading-spinner-mobile">
          <img src="/images/loading-icon.png" alt="Loading..." className="loading-logo" />
          <div className="progress-bar-container" data-testid="progress-bar-container">
            <div className="progress-bar" style={{ width: `${progress}%` }}></div>
          </div>
        </div>
      )}

      {/* Render the Sidebar only if not on mobile */}
      {!isMobile && (
        <aside className="app-sidebar-left" data-testid="crypto-sidebar">
          <CryptoPriceSidebar />
        </aside>
      )}

      {/* Main Content */}
      <main className="app-main-content" data-testid="main-content">
        <Navbar
          onShowAllNews={showAllNews}
          onShowLatestNews={showLatestNews}
          isMobile={isMobile}  // Pass isMobile to Navbar component
        />
        {!isMobile ? (
          <div className="content-wrapper" data-testid="content-wrapper">
            {loading && (
              <div className="loading-spinner-desktop" data-testid="loading-spinner-desktop">
                <img src="/images/loading-icon.png" alt="Loading..." className="loading-logo" />
                <div className="progress-bar-container" data-testid="progress-bar-container-desktop">
                  <div className="progress-bar" style={{ width: `${progress}%` }}></div>
                </div>
              </div>
            )}
            <div className="news-feed-wrapper" data-testid="news-feed-wrapper">
              <NewsFeed
                articles={filteredArticles}
                onSelectArticle={setSelectedArticleData}  // Pass both article and thumbnail
                selectedArticle={selectedArticleData?.article}  // Pass selected article object only
              />
            </div>
            <div className="article-details-wrapper" data-testid="article-details-wrapper">
              {selectedArticleData ? (
                <ArticleDetails 
                  article={selectedArticleData.article}  // Pass the article
                  thumbnail={selectedArticleData.thumbnail}  // Pass the selected thumbnail
                />
              ) : (
                <p>يرجى اختيار مقال لعرض التفاصيل.</p>
              )}
            </div>
          </div>
        ) : (
          <NewsAccordion articles={filteredArticles} data-testid="news-accordion" />
        )}
      </main>

      <footer className="app-footer" data-testid="app-footer">
        {/* Add TickerTapeWidget to the footer */}
        <TickerTapeWidget />
      </footer>
    </div>
  );
}

export default App;
