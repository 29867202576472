import React, { useState, useEffect } from 'react';
import './Navbar.css';
import whoWeAreText from '../text/WhoWeAreText'; // Import Who We Are text
import privacyPolicyText from '../text/PrivacyPolicyText'; // Import Privacy Policy text

function Navbar({ onShowAllNews, onShowLatestNews, isMobile }) {
  const [isWhoWeAreOpen, setWhoWeAreOpen] = useState(false);
  const [isPrivacyOpen, setPrivacyOpen] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false); // State to handle the Hamburger menu

  // Modal toggle functions
  const toggleWhoWeAre = () => {
    setWhoWeAreOpen(!isWhoWeAreOpen);
    setPrivacyOpen(false); // Close the privacy modal if it's open
  };

  const togglePrivacy = () => {
    setPrivacyOpen(!isPrivacyOpen);
    setWhoWeAreOpen(false); // Close the "Who We Are" modal if it's open
  };

  const toggleMenu = () => setMenuOpen(!isMenuOpen); // Toggle the Hamburger menu

  // Close the burger menu on click anywhere in the document
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (isMenuOpen && e.target.closest('.hamburger-menu') === null) {
        setMenuOpen(false); // Collapse the menu when clicking outside the hamburger
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isMenuOpen]);

  return (
    <>
      {/* Desktop Navbar */}
      {!isMobile && (
        <nav className="navbar" data-testid="desktop-navbar">
          <ul>
            <li onClick={onShowAllNews} data-testid="desktop-nav-home">CamelBits.com</li>
            <li onClick={togglePrivacy} data-testid="desktop-nav-privacy">سياسة الخصوصية و المسؤولية</li>
            <li onClick={toggleWhoWeAre} data-testid="desktop-nav-who-we-are">من نحن</li>
            <li onClick={onShowLatestNews} data-testid="desktop-nav-latest-news">🔥أخر الأخبار</li>
            <li onClick={onShowAllNews} data-testid="desktop-nav-all-news">💎جميع الأخبار</li>
          </ul>
        </nav>
      )}

      {/* Mobile Navbar with Hamburger */}
      {isMobile && (
        <nav className="mobile-navbar" data-testid="mobile-navbar">
          <div className="hamburger-menu" data-testid="hamburger-menu">
            <div className="hamburger-icon" onClick={toggleMenu} data-testid="hamburger-icon">☰</div> {/* Hamburger Icon */}
            <span className="mobile-navbar-brand" data-testid="mobile-navbar-brand">
              CamelBits.com {/* Brand Text */}
              <img
                src="/images/brand-icon.png"
                alt="Brand Icon"
                className="brand-image"
                data-testid="mobile-brand-image"
              /> {/* Brand Image */}
            </span>
          </div>
          {isMenuOpen && (
            <div className="mobile-menu" data-testid="mobile-menu">
              <ul>
                <li onClick={() => { onShowAllNews(); setMenuOpen(false); }} data-testid="mobile-nav-all-news">جميع الأخبار</li>
                <li onClick={() => { onShowLatestNews(); setMenuOpen(false); }} data-testid="mobile-nav-latest-news">🔥أخر الأخبار</li>
                <li onClick={() => { toggleWhoWeAre(); setMenuOpen(false); }} data-testid="mobile-nav-who-we-are">من نحن</li>
                <li onClick={() => { togglePrivacy(); setMenuOpen(false); }} data-testid="mobile-nav-privacy">سياسة الخصوصية و المسؤولية</li>
              </ul>
            </div>
          )}
        </nav>
      )}

      {/* Modal for "من نحن" */}
      {isWhoWeAreOpen && (
        <div className="modal" onClick={toggleWhoWeAre} data-testid="who-we-are-modal">
          <div className="modal-content" onClick={e => e.stopPropagation()} data-testid="who-we-are-modal-content">
            <h2>من نحن</h2>
            <p>{whoWeAreText}</p>
            <button onClick={toggleWhoWeAre} data-testid="who-we-are-close-btn">إغلاق</button>
          </div>
        </div>
      )}

      {/* Modal for "سياسة الخصوصية و المسؤولية" */}
      {isPrivacyOpen && (
        <div className="modal" onClick={togglePrivacy} data-testid="privacy-modal">
          <div className="modal-content" onClick={e => e.stopPropagation()} data-testid="privacy-modal-content">
            <h2>سياسة الخصوصية و المسؤولية</h2>
            <p>{privacyPolicyText}</p>
            <button onClick={togglePrivacy} data-testid="privacy-close-btn">أوافق على الشروط</button>
          </div>
        </div>
      )}
    </>
  );
}

export default Navbar;
